import axios from 'axios';
import { dataSources } from '../utils/data-sources';
import { Cookies } from 'react-cookie';
// import { connect } from 'react-redux';
// import { loadTokens } from "../stores/sessionActions";

let instance = null;

export function getHttpClient() {
  return axios.create();
}

class Http {
  constructor() {
    this.cookies = new Cookies();
    this.access_token = this.cookies.get('access_token');
    this.IAM_id = this.cookies.get('IAM_id');
    this.refresh_token = this.cookies.get('refresh_token');
    this.locale = this.cookies.get('locale');
    if (!instance) {
      instance = this;
    }
    this.authRefreshInterceptor = null;
    this.authRequestInterceptor = null;
    this.dataSources = dataSources();
    this.axiosInstance = getHttpClient();
    this._createAuthRefreshInterceptor();
    this._createAuthRequestInterceptor();

    return instance;
  }

  _createAuthRequestInterceptor() {
    this.authRequestInterceptor = this.axiosInstance.interceptors.request.use(
      config => {
        let access = this.access_token;
        config.headers['Authorization'] = `Bearer ${access}`;
        return config;
      },
      error => {
        console.log(error);
        return Promise.reject(error);
      },
    );
  }

  _createAuthRefreshInterceptor() {
    this.authRefreshInterceptor = this.axiosInstance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        let errorResponse = error.response;
        if (errorResponse.status === 401) {
          let refresh = this.refresh_token;
          this.axiosInstance.interceptors.response.eject(this.authRefreshInterceptor);
          return getHttpClient()
            .post(this.dataSources.refreshToken, null, {
              headers: { Authorization: `Bearer ${refresh}` },
            })
            .then(response => {
              this.access_token = response.data.access_token;
              this.cookies.set('access_token', this.access_token, {
                path: '/',
                domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
              });
              errorResponse.config.headers['Authorization'] = 'Bearer ' + this.access_token;
              return this.axiosInstance(errorResponse.config);
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.destroySession();
                window.location.href = `${process.env.REACT_APP_myBNIURL}`;
                return Promise.reject(error);
              }
              return Promise.reject(error);
            });
        } else if (errorResponse.status === 403 || errorResponse.status === 401) {
          this.destroySession();
          window.location.href = `${process.env.REACT_APP_myBNIURL}`;
          return Promise.reject(error);
        }
        return Promise.reject(error);
      },
    );
  }

  refreshToken() {
    return getHttpClient()
      .post(this.dataSources.refreshToken, {})
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (error.response.status === 401) {
        }
      });
  }

  static getInstance() {
    return new Http();
  }

  intercept(error) {
    return Promise.reject(error);
  }

  getLocale() {
    let locale = this.cookies.get('locale');
    return locale;
  }

  _getOptions(opts) {
    return Object.assign(
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
      opts,
    );
  }

  _getTranslationsOptions(opts) {
    return Object.assign(
      {
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': '${process.env.REACT_APP_BNITranslationKey}',
        },
      },
      opts,
    );
  }

  _getTermsOptions(opts, data) {
    return Object.assign(
      {
        IAM_id: data['IAM_id'],
        tos_charter_name: data['tos_charter_name'],
        tos_version: data['tos_version'],
      },
      opts,
    );
  }

  _cleanUrl(url) {
    if (/\/static.*\.json/.test(url)) {
      return url.substring(0, url.lastIndexOf('.json') + 5);
    }
    return url;
  }

  getData(url, options, IAM_id) {
    this.access_token = this.cookies.get('access_token');
    this.refresh_token = this.cookies.get('refresh_token');
    this.IAM_id = this.cookies.get('IAM_id');
    return this.axiosInstance
      .get(this._cleanUrl(url), this._getOptions(options))
      .then(response => {
        return response;
      })
      .catch(e => {
        return e;
      });
  }

  getTranslationData(url, options) {
    this.access_token = this.cookies.get('access_token');
    this.refresh_token = this.cookies.get('refresh_token');
    this.IAM_id = this.cookies.get('IAM_id');
    return this.axiosInstance
      .get(this._cleanUrl(url), this._getTranslationsOptions(options))
      .then(response => {
        return response;
      })
      .catch(e => {
        return e;
      });
  }

  postLoginData(url, data, options) {
    return axios
      .post(url, data, options)
      .then(response => {
        return response;
      })
      .catch(error => {
        return this.intercept(error);
      });
  }

  getStatusData() {
    return axios({
      method: 'get',
      url: `${this.dataSources.status}`,
    })
      .then(response => {
        return response;
      })
      .catch(response => {
        return response;
      });
  }

  getTranslationApiStatusData() {
    return axios({
      method: 'get',
      url: `${this.dataSources.i18nstatus}/ping`,
    })
      .then(response => {
        return response;
      })
      .catch(response => {
        return response;
      });
  }

  getPortalApiStatusData() {
    return axios({
      method: 'get',
      url: `${this.dataSources.status}/ping`,
    })
      .then(response => {
        return response;
      })
      .catch(response => {
        return response;
      });
  }

  postTermsData(url, data, options) {
    this.access_token = this.cookies.get('access_token');
    this.refresh_token = this.cookies.get('refresh_token');
    this.IAM_id = this.cookies.get('IAM_id');
    return this.axiosInstance
      .post(this._cleanUrl(url), this._getTermsOptions(options, data))
      .then(response => {
        return response;
      })
      .catch(e => {
        return e;
      });
    // return axios.post(url, data, options)
    //   .then((response) => {
    //     return response
    //   }).catch((error) => {
    //     return this.intercept(error)
    //   })
  }

  postData(url, data, options) {
    this.access_token = this.cookies.get('access_token');
    this.refresh_token = this.cookies.get('refresh_token');
    this.IAM_id = this.cookies.get('IAM_id');
    return this.axiosInstance
      .post(this._cleanUrl(url), this._getOptions(options))
      .then(response => {
        return response;
      })
      .catch(e => {
        return e;
      });
    // return axios.post(url, data, options)
    //   .then((response) => {
    //     return response
    //   }).catch((error) => {
    //     return this.intercept(error)
    //   })
  }

  destroy() {
    return this.postData(this.dataSources.logout)
      .then(res => {
        this.destroySession();
        return res;
      })
      .catch(() => {
        this.destroySession();
      });
  }

  destroySession = () => {
    this.cookies.remove('access_token', {
      path: '/',
      domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
    });
    this.cookies.remove('refresh_token', {
      path: '/',
      domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
    });
    this.cookies.remove('IAM_id', {
      path: '/',
      domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
    });
    this.cookies.remove('name', {
      path: '/',
      domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
    });
    this.cookies.remove('browser_locale', {
      path: '/',
      domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
    });
    this.cookies.remove('locale', {
      path: '/',
      domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
    });
    sessionStorage.clear();
  };
}

// function mapStateToProps(state) {
//   const { loadTokens } = state;
//   return { loadTokens };
// }
export default Http;
