import React, { useEffect } from 'react';
import { Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { makeStyles, Button, Menu, MenuItem } from '@material-ui/core';
import { AccountCircle, KeyboardArrowDown } from '@material-ui/icons';
import * as staticTextKey from '../../utils/translation-variable';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  textCase: {
    textTransform: 'none',
    fontSize: 18,
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  link: {
    textDecoration: 'none !important',
    color: '#64666A !important',
  },
}));

export function ProfileMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [logoutText, setlogoutText] = React.useState('');
  let cookies = new Cookies();
  let name = cookies.get('name');

  // useEffect(() => {
  //   let translationData = JSON.parse(sessionStorage.getItem('translations'));
  //   if (translationData !== null) {
  //     setlogoutText(translationData[staticTextKey.logoutValue]);
  //   }
  // }, []);

  useEffect(() => {
    (async () => {
      await getTranslationData();
    })();
  }, []);

  const getTranslationData = () => {
    var language = window.navigator.userLanguage || window.navigator.language;
    console.log(language);
    language = 'en-US';
    const cookies = new Cookies();
    var selectedLanguageCookie = cookies.get('bni_language_select');
    console.log(selectedLanguageCookie);
    language = selectedLanguageCookie !== undefined ? selectedLanguageCookie : language;

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_I18NAPI}/translations/${language}/search`,
      data: {
        has_translation: true,
        group_code: 'portal.login',
        page_size: 100,
      },
      headers: {
        'Content-Type': 'application/json',
        client_name: `${process.env.REACT_APP_clientName}`,
        client_secret: `${process.env.REACT_APP_clientSecret}`,
        'x-iso-ids-only': 'N',
      },
    })
      .then(response => {
        const translationRawData = response.data.translations;

        let portaltranslation = {};
        translationRawData.map(function (row) {
          portaltranslation[row.lang_key] =
            row.lang_value ? row.lang_value : row.en_val
        });  
        setlogoutText(portaltranslation[staticTextKey.logoutValue]);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });

    };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="container-right">
      <Button
        color="inherit"
        className={classes.textCase}
        startIcon={<AccountCircle style={{ fontSize: 40 }} />}
        endIcon={<KeyboardArrowDown />}
        onClick={handleClick}>
        {name}
      </Button>
      <Menu
        className="drop-downmenu profile-dropdown"
        id="fsdsadsa"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}>
        <Link className={classes.link} to="/logout">
          <MenuItem>{logoutText}</MenuItem>
        </Link>
      </Menu>
    </div>
  );
}
