import React from 'react';
import Http from '../../services/http';
import { dataSources } from '../../utils/data-sources';
import { LoginLayout } from '../../components/login-layout/login-layout-new';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { Box, Button, Typography, Grid, Paper } from '@material-ui/core';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import { HandleErrors } from '../../components/handle-errors';
import { staticTranslationData } from '../../utils/static-translation-text';
import * as staticTextKey from '../../utils/translation-variable';

export class TermsPage extends React.Component {
  dataSources = dataSources();
  http = Http.getInstance();
  state = {
    termsVersion: 1,
    policyVersion: 1,
    termsDeclined: false,
    termsAccepted: false,
    tos_charter_name: '',
    tos_version: '',
    login_name: '',
    termsURL: '',
    ready: false,
    errorDisplay: false,
    errorResponse: {},
    cancelValue: '',
    acceptValue: '',
    tAndCValue: '',
    tosAndPpValue: '',
    appId: '',
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleAccept = () => {
    const cookies = new Cookies();
    let IAM_id = cookies.get('IAM_id');

    let appUrl;
    let appId;

    if (this.state.appId !== undefined) {
      appId = this.state.appId === undefined ? 'undefined' : this.state.appId;
    }

    if (this.state.appUrl !== undefined) {
      appUrl = this.state.appUrl === undefined ? 'undefined' : this.state.appUrl;
    }

    let url = `${this.dataSources.users}/${IAM_id}/apps/${appId}/tos`;

    return this.http
      .postData(url, '', '')
      .then(res => {
        if (res.status === 200) {
          // console.log(res);
          if (appId !== process.env.REACT_APP_BNIPortalId) {
            appUrl = this.state.appUrl;
            window.location.href = appUrl;
          } else {
            this.setState(state => ({
              termsAccepted: true,
            }));
          }
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({
          errorDisplay: true,
          errorResponse: error.response,
        });
      });
  };

  handleCancel = () => {
    const cookies = new Cookies();
    let appId;

    if (this.state.appId !== undefined) {
      appId = this.state.appId === undefined ? 'undefined' : this.state.appId;
    }

    if (appId === process.env.REACT_APP_BNIPortalId) {
      let url = `${process.env.REACT_APP_myBNIURL}`;
      this.http.destroy().then(viewData => {
        //
        this.setState(state => ({ termsDeclined: true }));
        cookies.remove('access_token', {
          path: '/',
          domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
        });
        cookies.remove('refresh_token', {
          path: '/',
          domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
        });
        cookies.remove('IAM_id', {
          path: '/',
          domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
        });
        cookies.remove('name', {
          path: '/',
          domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
        });
        cookies.remove('browser_locale', {
          path: '/',
          domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
        });
        cookies.remove('locale', {
          path: '/',
          domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
        });
      });
    } else {
      let url = `${process.env.REACT_APP_myBNIURL}launcher`;
      window.location.href = url;
    }
  };

  getTranslationData = () => {
    let locale = this.http.getLocale();
    const cookies = new Cookies();
    let bniLang = cookies.get('bni_language_select');
    let lang = bniLang !== undefined ? bniLang : locale.lang_code;
    // axios({
    //   method: 'post',
    //   url: `${this.dataSources.translationSearch}`,
    //   data: {
    //     lang: bniLang !== undefined ? bniLang : locale.lang_code,
    //     keys: staticTranslationData,
    //   },
    //   headers: { 'X-API-KEY': `${process.env.REACT_APP_BNITranslationKey}` },
    // })
    axios({
      method: 'post',
      url: `${this.dataSources.translateApi}/${lang}/search`,
      data: {
        has_translation: true,
        group_code: 'portal.login',
        page_size: 100,
      },
      headers: {
        'Content-Type': 'application/json',
        client_name: `${process.env.REACT_APP_clientName}`,
        client_secret: `${process.env.REACT_APP_clientSecret}`,
        'x-iso-ids-only': 'N',
      },
    })
      .then(response => {
        const translationRawData = response.data.translations;

        let portaltranslation = {};
        translationRawData.map(function (row) {
          portaltranslation[row.lang_key] =
            row.lang_value !== undefined ? row.en_val : row.lang_value;
        });
        //handle success
        sessionStorage.setItem('lang', JSON.stringify(lang));
        sessionStorage.setItem('translations', JSON.stringify(portaltranslation));
        this.setState({
          cancelValue: portaltranslation[staticTextKey.cancelValue],
          acceptValue: portaltranslation[staticTextKey.acceptValue],
          tAndCValue: portaltranslation[staticTextKey.tAndCValue],
          tosAndPpValue: portaltranslation[staticTextKey.tosAndPpValue],
        });
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  componentDidMount() {
    if (document.cookie.split(';').filter(item => item.includes('access_token')).length) {
      this.setState(
        {
          loadData: true,
        },
        () => this.loadData(),
      );
    } else {
      window.location.href = `${process.env.REACT_APP_myBNIURL}`;
    }

    if (sessionStorage.getItem('translations') == null) {
      this.getTranslationData();
    }

    const cookies = new Cookies();
    let access_token = cookies.get('access_token');
  }

  async loadData() {
    await this.appAccess();
    await this.termsData();
    await this.getTranslationData();
  }

  appAccess() {
    let appId;
    const cookies = new Cookies();
    let IAM_id = cookies.get('IAM_id');
    let launcherTermsStatus;

    if (this.props.location.state !== undefined) {
      appId =
        this.props.location.state.appId === undefined
          ? 'undefined'
          : this.props.location.state.appId;
    }

    let returnAppName = window.location.href.split('?')[1];
    if (returnAppName !== undefined) {
      appId = returnAppName;
    }

    if (appId === undefined) {
      appId = process.env.REACT_APP_BNIPortalId;
    }

    let url = `${this.dataSources.users}/${IAM_id}/apps/${appId}/tos`;
    return this.http
      .getData(url, '', '')

      .then(res => {
        if (res.status === 200) {
          launcherTermsStatus = res.data.status;
          this.setState({
            ready: true,
            termsAccepted: launcherTermsStatus,
            appId: appId,
          });
        } else {
          this.setState({
            ready: true,
            errorDisplay: true,
            errorResponse: res.response,
          });
        }
      })
      .catch(error => {
        this.setState({
          ready: true,
          errorDisplay: true,
          errorResponse: error.response,
        });
      });
  }

  termsData() {
    // let appName;
    let launcherPolicyUrl;
    let appUrl;
    let tos_charter_name;
    let tos_charter_version;

    const cookies = new Cookies();
    let appId;
    let IAM_id = cookies.get('IAM_id');

    // check state for app id
    if (this.props.location.state !== undefined) {
      appId =
        this.props.location.state.appId === undefined
          ? 'undefined'
          : this.props.location.state.appId;
    }

    // check query params for app id
    let returnAppName = window.location.href.split('?')[1];
    if (returnAppName !== undefined) {
      appId = returnAppName;
    }

    let url = `${this.dataSources.users}/${IAM_id}`;
    return this.http
      .getData(url, '', '')
      .then(res => {
        if (res.status === 200) {
          let apps = res.data[0].apps;
          if (appId === undefined) {
            appId = process.env.REACT_APP_BNIPortalId;
          }
          // find active Tos id if list of apps contain the app id passed into this page.
          let activeTosdetails = apps.find(x => x._id === appId).active_tos_ids._id;
          appUrl = apps.find(x => x._id === appId).url;

          if (activeTosdetails !== undefined) {
            let url = `${this.dataSources.tos}/${activeTosdetails}`;

            return this.http
              .getData(url, '', '')
              .then(res => {
                if (res.status === 200) {
                  launcherPolicyUrl = res.data[0].urls[0].url;
                  tos_charter_name = res.data[0].tos_name;
                  tos_charter_version = res.data[0].version;
                }

                this.setState({
                  ready: true,
                  termsURL: launcherPolicyUrl,
                  tos_charter_name: tos_charter_name,
                  tos_version: tos_charter_version,
                  appId: appId,
                  appUrl: appUrl,
                });
              })
              .catch(error => {});
          }
        } else {
          this.setState({
            ready: true,
            errorDisplay: true,
            errorResponse: res.response,
          });
        }
      })
      .catch(error => {
        this.setState({
          ready: true,
          errorDisplay: true,
          errorResponse: error.response,
        });
      });
  }

  render(props) {
    let cancelText = this.state.cancelValue;
    let acceptText = this.state.acceptValue;
    let tAndCText = this.state.tAndCValue;
    let tosAndPpText = this.state.tosAndPpValue;
    const translationsData = JSON.parse(sessionStorage.getItem('translations'));
    if (this.state.termsAccepted === true) {
      return (
        <Redirect
          to={{
            pathname: '/launcher',
            state: {
              termsAccepted: this.state.termsAccepted,
            },
          }}
        />
      );
    }

    if (this.state.termsDeclined === true) {
      return <Redirect to="/" />;
    }
    if (this.state.ready) {
      return (
        <React.Fragment>
          {!this.state.errorDisplay && (
            <LoginLayout title="Login">
              <Paper elevation={3}>
                <div className="container-wrap">
                  <Typography
                    className="terms-align-center login-top-padding login-bottom-padding"
                    variant="h5">
                    {tAndCText}
                  </Typography>
                  <div className="form-group form-text-color terms-align-padding terms-align-center">
                    <Box>
                      <Box component="span" m={1}>
                        <a href={this.state.termsURL} target="_blank" rel="noopener noreferrer">
                          {tosAndPpText}
                        </a>
                      </Box>
                    </Box>
                  </div>

                  <Grid container spacing={3}>
                    <Grid item sm={6}>
                      <Button
                        variant="outlined"
                        className=" classes.button MuiButton-fullWidth button-focus-disable"
                        onClick={this.handleCancel}
                        id="cancel">
                        {cancelText}
                      </Button>
                    </Grid>
                    <Grid item sm={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable"
                        onClick={this.handleAccept}
                        id="accept">
                        {acceptText}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </LoginLayout>
          )}
          {this.state.errorDisplay && <HandleErrors errorData={this.state.errorResponse} />}
        </React.Fragment>
      );
    } else {
      return <div>&nbsp;</div>;
    }
  }
}

export default withRouter(TermsPage);
