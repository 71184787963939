export const userNameValue = 'portal-login-username';
export const passwordValue = 'portal-login-password';
export const forgotPasswordValue = 'portal-login-forgot_password';
export const loginValue = 'portal-login-login';
export const tAndCValue = 'portal-terms-t_and_c';
export const tosAndPpValue = 'portal-terms-tos_and_pp';
export const cancelValue = 'portal-terms-cancel';
export const acceptValue = 'portal-terms-accept';
export const logoutValue = 'portal-launcher-logout';
export const noAppsAssignedValue = 'portal-launcher-no_apps_assigned';
export const copyrightValue = 'portal-launcher-copyright';
export const ppValue = 'portal-launcher-pp';
export const infoValue = 'portal-launcher-info';
export const DEFAULT_ENGLISH_TRANSLATION = { iso_id: 'en-US', display_name: 'English (US)' };