import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { LoginPage } from '../../pages/login';
import { TermsPage } from '../../pages/terms';
import { LauncherPage } from '../../pages/launcher';
import LogoutPage from '../../pages/logout';
import OauthPage from '../../pages/oauth';
// import { Provider } from 'react-redux';
import '../../scss/main.scss';
import theme from '../../utils/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <React.Fragment>
          <Route exact path="/" component={LoginPage} />
          <Route path="/terms" component={TermsPage} />
          <Route path="/launcher" component={LauncherPage} />
          <Route exact path="/oauth/authorize" component={OauthPage} />
          <Route exact path="/logout" component={LogoutPage} />
        </React.Fragment>
      </Router>
    </ThemeProvider>
  );
}

export default App;
